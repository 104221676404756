import React from "react"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import BannerHeroImage from "../components/_shared/BannerHeroImage"
import ContactUsSection from "../components/_shared/ContactUsSection"

import CompanyCharacteristicsSection from "../components/Company/CompanyCharacteristicsSection"
import OurNumbers from "../components/Company/OurNumbers"

// ----------------------------------------------------------------------------
const ServicesPage = () => (
  <Layout pageTitle="Azienda">
    <SEO title="Azienda" />

    <BannerHeroImage
      image={require("../images/company/hero.png")}
      bannerText={`Gestiamo oltre 100mila prodotti suddivisi in\n300 categorie merceologiche differenti`}
      highlightedText={"300 categorie"}
    />
    <CompanyCharacteristicsSection />
    <OurNumbers image={require("../images/company/our-numbers.png")} />
    <ContactUsSection />
  </Layout>
)

export default ServicesPage
