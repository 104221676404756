import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import RevealOnScroll from "../../_shared/RevealOnScroll"
import IconTextCard from "../../_shared/IconTextCard"
import MobileSiteSlider from "../../_shared/SiteSlider"

// ----------------------------------------------------------------------------

const SLIDES = [
  <IconTextCard
    key={1}
    icon={require("../../../images/company/icons/box-icn.svg")}
    title="+ 100mila prodotti"
    description="È la portata del nostro catalogo che gestiamo quotidianamente."
  />,
  <IconTextCard
    key={2}
    icon={require("../../../images/company/icons/world-icn.svg")}
    title="129 paesi diversi"
    description="Raggiunti tramite le nostre spedizioni finora: ma possiamo spedire in tutto il mondo!"
  />,
  <IconTextCard
    key={3}
    icon={require("../../../images/company/icons/cart-icn.svg")}
    title="Oltre 15 marketplace"
    description="Siamo presenti sul mercato sin dai primi anni di nascita degli e-commerce."
  />,
  <IconTextCard
    key={4}
    icon={require("../../../images/company/icons/headphone-icn.svg")}
    title="5 lingue"
    description="Numero di lingue gestite dal nostro servizio clienti."
  />,
]

//------------------------------------------------------------------------------

function WhyChooseUsSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // ------------------------------------ -
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const defaultIsMobile = matchMobileMediaQuery()

  const [isMobile, setisMobile] = useState(defaultIsMobile)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowResize)
    }
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleWindowResize() {
    setisMobile(matchMobileMediaQuery())
  }

  function matchMobileMediaQuery() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  function renderSlide(index) {
    return SLIDES[index]
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <h6>I NOSTRI NUMERI</h6>

      <h2>Sales Bridge: un’azienda del nuovo millennio.</h2>

      {typeof window !== "undefined" && isMobile && (
        <div className="mobile-slider-container">
          <MobileSiteSlider
            slides={SLIDES}
            renderSlide={renderSlide}
            height={341.41}
          />
        </div>
      )}

      {typeof window !== "undefined" && !isMobile && (
        <div className="cards-container">
          <RevealOnScroll delay={100}>{SLIDES[0]}</RevealOnScroll>

          <RevealOnScroll delay={200}>{SLIDES[1]}</RevealOnScroll>

          <RevealOnScroll delay={300}>{SLIDES[2]}</RevealOnScroll>

          <RevealOnScroll delay={400}>{SLIDES[3]}</RevealOnScroll>

          <RevealOnScroll delay={500}>
            <IconTextCard
              icon={require("../../../images/company/icons/headphone-icn.svg")}
              title="5 lingue"
              description="Numero di lingue gestite dal nostro servizio clienti."
            />
          </RevealOnScroll>
        </div>
      )}
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

WhyChooseUsSection.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object,
}

WhyChooseUsSection.defaultProps = {}

// ----------------------------------------------------------------------------

const WhyChooseUsSectionWithTheme = withTheme(WhyChooseUsSection)

// ----------------------------------------------------------------------------

const StyledWhyChooseUsSection = styled(WhyChooseUsSectionWithTheme)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 100vw;
    min-height: 1077px;
    max-width: 100vw;

    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center center;

    > h6 {
      margin-top: 99px;
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${props => props.theme.jonquil};
    }

    > h2 {
      text-align: center;
      color: ${props => props.theme.white};
      margin-bottom: 43px;
    }

    .cards-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      max-width: 980px;

      > * {
        margin-top: 12px;
        margin-right: 12px;
      }
    }

    .mobile-slider-container {
      margin-bottom: 50px;
      width: 100vw;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      min-height: auto;

      .cards-container {
        margin-bottom: 50px;
        box-sizing: border-box;
        padding: 0 26px;
        width: 100vw;

        > * {
          margin-right: 0;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledWhyChooseUsSection
