import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function ImageCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { renderContent } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <div className="background-img" />

      <div className="card-text-container">{renderContent()}</div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ImageCard.propTypes = {
  className: PropTypes.string.isRequired,
  renderContent: PropTypes.func,
}

ImageCard.defaultProps = {
  renderContent: () => {},
}

// ----------------------------------------------------------------------------

const StyledImageCard = styled(ImageCard)`
  & {
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.ashGrey};
    border-radius: 4px;
    width: 961px;
    min-height: 746px;
    overflow: hidden;

    .background-img {
      width: 100%;
      height: 431px;
      background-image: url(${props => props.image});
      background-size: cover;
      background-position: center center;
    }

    .card-text-container {
      padding: 60px 80px 49px 49px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      border: none;
      width: 100%;
      max-width: 100%;

      .background-img {
        width: 100%;
        height: 218px;
      }

      .card-text-container {
        padding: 28.7px 0;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledImageCard
