import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import RevealOnScroll from "../../_shared/RevealOnScroll"

import ImageCard from "../ImageCard"

//------------------------------------------------------------------------------

function CompanyCharacteristics(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <RevealOnScroll>
        <ImageCard
          image={require("../../../images/company/chuttersnap.png")}
          renderContent={() => {
            return (
              <div className="card-content">
                <div className="left">
                  <h6>ACQUISTI</h6>

                  <h2>
                    Siamo il tuo partner
                    <br />
                    strategico per le
                    <br />
                    vendite online.
                  </h2>
                </div>

                <div className="right">
                  <p>
                    <h4>Acquisti in gran quantità</h4>
                    <p>
                      Emettiamo e gestiamo piccoli e grandi ordini d’acquisto.
                    </p>
                  </p>

                  <p>
                    <h4>Pagamenti sicuri e snelli</h4>
                    <p>
                      Siamo il tuo unico interlocutore con termini di pagamento
                      chiari e condivisi.
                    </p>
                  </p>
                </div>
              </div>
            )
          }}
        />
      </RevealOnScroll>
      <RevealOnScroll>
        <ImageCard
          image={require("../../../images/company/elevate.png")}
          renderContent={() => {
            return (
              <div className="card-content">
                <div className="left">
                  <h6>TRASPARENZA</h6>

                  <h2>
                    Tuteliamo in ogni
                    <br />
                    modo i nostri partner.
                    <br />
                    Sempre.
                  </h2>
                </div>

                <div className="right">
                  <p>
                    <h4>Posizionamento condiviso</h4>
                    <p>
                      Rispettiamo e concordiamo le strategie di posizionamento
                      preesistenti della tua azienda.
                    </p>
                  </p>

                  <p>
                    <h4>Pricing condiviso</h4>
                    <p>
                      Adottiamo politiche di pricing e distributive condivise.
                    </p>
                  </p>
                </div>
              </div>
            )
          }}
        />
      </RevealOnScroll>
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CompanyCharacteristics.propTypes = {
  className: PropTypes.string.isRequired,
}

CompanyCharacteristics.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCompanyCharacteristics = styled(CompanyCharacteristics)`
  & {
    margin-top: 84px;
    margin-bottom: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 40px;

      .card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h6 {
          margin-bottom: 14.6px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: ${props => props.theme.yaleBlue};
        }

        h2 {
          color: ${props => props.theme.primaryTextColor};

          > .highlighted-text {
            background-color: ${props => props.theme.pearlAqua};
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          max-width: 300px;

          h4 {
            margin-bottom: 8px;
            font-size: 21px;
            line-height: 24px;
            font-weight: 600;
            color: ${props => props.theme.primaryTextColor};
          }

          p {
            font-size: 18px;
            line-height: 24px;
            color: ${props => props.theme.primaryTextColor};
          }

          > p {
            :first-child {
              margin-bottom: 14px;
            }
          }
        }
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      box-sizing: border-box;
      padding: 0 26px;
      max-width: 100vw;

      > * {
        max-width: 100%;
        margin-bottom: 40px;

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: unset;
          align-items: center;

          h2,
          h6 {
            text-align: center;
          }

          h6 {
            margin-bottom: 0;
          }

          h2 {
            margin-bottom: 6.4px;
          }

          .right {
            margin-top: 24.9px;

            h4,
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCompanyCharacteristics
